<template>
  <div :class="$style.promos">
    <div :class="$style.wrapper">
      <el-button
        :class="$style.button"
        type="primary"
        @click="$router.push('vacancies/create')"
      >
        Создать
      </el-button>
    </div>
    <el-table :data="vacancies" stripe>
      <el-table-column prop="name" label="Вакансия" width="160">
      </el-table-column>
      <el-table-column prop="experience" label="Опыт работы"> </el-table-column>
      <el-table-column
        prop="employment"
        label="Занятость"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="publishedAt" label="Дата публикации">
        <template slot-scope="scope">
          {{ formatDateFullYear(scope.row.publishedAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="isArchived" label="В архиве" width="150">
        <template slot-scope="scope">
          <input
            :class="$style.checkbox"
            type="checkbox"
            onclick="return false"
            :checked="scope.row.isArchived"
          />
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :edit-link="'vacancies/' + String(scope.row.id)"
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import { formatDateFullYear } from '@/helpers/index.js'

export default {
  components: { ActionButtons },
  data() {
    return {
      total: 0,
      page: 1,
      limit: 20,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.VacanciesActions.getList({
          limit: this.limit,
          page: this.page,
        })
      if (error) return
      this.vacancies = value.data
      this.total = value.meta.count

      loading.close()
    },
    async remove(id) {
      const isConfirm = confirm('Вы точно хотите удалить вакансию?')
      if (!isConfirm) {
        return
      }
      const { error } =
        await delivery.ContentServiceCore.VacanciesActions.delete(id)
      if (!error) {
        await this.getList()
      }
    },
    formatDateFullYear(date) {
      return !date ? '' : formatDateFullYear(date)
    },
  },
}
</script>

<style lang="scss" module>
.promos {
  padding: 1rem;

  .wrapper {
    display: flex;
    justify-content: flex-end;

    .button {
      margin-bottom: 1rem;
    }
  }

  .pagination {
    @include pagination;
  }
}

.checkbox {
  pointer-events: none;
}
</style>
